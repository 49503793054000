import { observer } from "mobx-react-lite";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "..";
import { ReactComponent as Arrow } from "../assets/svg/arrow-circle-down.svg";
import "../css/transition-layer.css";
import { independentPaths, paths } from "../paths";
import { serverUrls } from "../utils/consts";
import { toggleActive } from "../utils/helpers";
import Button from "./Button";
import logoImg from "../assets/img/logo.png";
import userStore from "../store/userStore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import backgroundImg from "../assets/img/background/transtition-mountains.png";
import shapkaImg from "../assets/img/shapka.png";
import { transform } from "framer-motion";
import Dropdown from "./Dropdown";
import SnowFlakes from "./snowFlakes/SnowFlakes";

const TransitionLayer = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [isShowNewYearPopup, setIsShowNewYearPopup] = useState(false);
  const [isSnowy, setIsSnowy] = useState(false);

  const handleScroll = () => {
    if (location.pathname !== independentPaths.TransitionPage) {
      setScrollY(window.scrollY);
    } else {
      setScrollY(0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function chooseEvrima(e) {
    userStore.setChoosedServerUrl(serverUrls.evrima);

    let path = location.pathname.replace(serverUrls.legacy, serverUrls.evrima);
    if (path === "/") {
      path = serverUrls.evrima;
    }

    // ждем анимацию 1 секунду перед переходом
    if (location.pathname === independentPaths.TransitionPage) {
      setIsActive(e);
      setTimeout(() => {
        navigate(path);
      }, 1000);
    } else {
      navigate(path);
    }
  }
  // function chooseLegacy(e) {
  //   userStore.setChoosedServerUrl(serverUrls.legacy);

  //   let path = location.pathname.replace(serverUrls.evrima, serverUrls.legacy);
  //   if (path === "/") {
  //     path = serverUrls.legacy;
  //   }

  //   if (location.pathname === independentPaths.TransitionPage) {
  //     setIsActive(e);
  //     setTimeout(() => {
  //       navigate(path);
  //     }, 1000);
  //   } else {
  //     navigate(path);
  //   }
  // }

  function showNewYearPopup(e) {
    console.log("hovered");
    setIsShowNewYearPopup(true);
  }

  function hideNewYearPopup(e) {
    console.log("unhovered");
    setIsShowNewYearPopup(false);
  }

  useEffect(() => {
    if (location.pathname === independentPaths.TransitionPage) {
      setIsActive(false);
    }
    if (location.pathname.startsWith(serverUrls.evrima)) {
      userStore.setChoosedServerUrl(serverUrls.evrima);
    } else if (location.pathname.startsWith(serverUrls.legacy)) {
      userStore.setChoosedServerUrl(serverUrls.legacy);
    }
  }, [location.pathname, userStore]);

  return (
    <div
      className={`transition-layer active-handler${
        location.pathname === independentPaths.TransitionPage ? " page" : ""
      }${isActive ? " active" : ""}`}
    >
      <div
        className="title"
        style={{
          transform: `translateY(${scrollY * 0.5}px)`, // Эффект параллакса
        }}
      >
        <div className="page-headings">
          {userStore.pageName.addition && (
            <p className="addition-heading gradient-text accent">
              {userStore.pageName.addition}
            </p>
          )}
          <div
            className="page-heading-container"
            // onMouseEnter={showNewYearPopup}
            // onMouseLeave={hideNewYearPopup}
          >
            <h1 className="page-heading text-shadow accent animate">
              {userStore.pageName.name ? (
                <span className="shapka-container">
                  {userStore.pageName.name}
                  <LazyLoadImage
                    src={shapkaImg}
                    alt=""
                    width={80}
                    className="new-year-shapka"
                    style={{
                      color: "blue",
                      transform: "scaleX(-1) matrix(-1 0 0 1 41 0)",
                    }}
                  />
                </span>
              ) : (
                <>
                  <span className="shapka-container">
                    {"MEG"}
                    <LazyLoadImage
                      src={shapkaImg}
                      alt=""
                      width={80}
                      className="new-year-shapka"
                      style={{
                        color: "blue",
                        transform: "scaleX(-1) matrix(-1 0 0 1 41 0)",
                      }}
                    />
                  </span>

                  <LazyLoadImage
                    effect="opacity"
                    src={logoImg}
                    alt="A"
                    className="logo"
                  />
                  {"LODON"}
                </>
              )}
            </h1>
            {/* {isShowNewYearPopup && (
              <Dropdown isOpen={true} noArrow={true}>
                <p>Хотите новогоднего настроения?</p>
                <Button onClick={setIsSnowy((prev) => !prev)}>Конечно!</Button>
              </Dropdown>
            )}
            <div className="hoverable-element" /> */}
          </div>
        </div>
      </div>
      <div className="servers ">
        <Button
          className={`svg-text no-border no-background${
            userStore.choosedServerUrl === serverUrls.evrima
              ? " text-shadow accent animate slow"
              : ""
          }`}
          onClick={chooseEvrima}
        >
          <Arrow />
          EVRIMA
        </Button>
        <Button
          className={`no-border no-background${
            userStore.choosedServerUrl === serverUrls.legacy
              ? " text-shadow accent animate slow"
              : ""
          } disabled`}
          // onClick={chooseLegacy}
        >
          <Arrow />
          LEGACY
        </Button>
      </div>
      <div className="transition-background">
        <LazyLoadImage src={backgroundImg} alt="" />
      </div>
      <SnowFlakes />
    </div>
  );
};

export default observer(TransitionLayer);
