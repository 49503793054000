import greenImg from "../assets/img/case/green-case.png";
import luckyImg from "../assets/img/case/lucky-case.png";
import mediumImg from "../assets/img/case/medium.png";
import busterImg from "../assets/img/busters/buster.png";
import eggImg1 from "../assets/img/eggs/puertosaur.png";
import eggImg2 from "../assets/img/eggs/spinosaur.png";
import { dinosaurGrowths, dinosaurTypes, megaShopTypes } from "./consts";

const casesData = [
  {
    image: luckyImg,
    teeth: 75,
    oa: 300,
  },
  {
    image: greenImg,
    teeth: 50,
    oa: 200,
  },
  {
    image: mediumImg,
    teeth: 100,
    oa: 400,
  },
];

casesData.forEach((item) => {
  item.type = megaShopTypes.case;
});

const bustersData = [
  {
    image: busterImg,
    teeth: 50,
    oa: 200,
  },
];

bustersData.forEach((item) => {
  item.type = megaShopTypes.buster;
});

const eggsData = [
  {
    image: eggImg1,
    teeth: 100,
    oa: 400,
  },
  {
    image: eggImg2,
    teeth: 50,
    oa: 200,
  },
];

eggsData.forEach((item) => {
  item.type = megaShopTypes.egg;
});

// const dinosaursData2 = [
//   {
//     image: dinosaurImg1,
//     tier: 1,
//     name: "Пситтакозавр",
//     type: dinosaurTypes.herbivorous,
//     teeth: 75,
//     oa: 300,
//     growth: dinosaurGrowths.Sub,
//     weight: 50,
//     damage: 25,
//     speed: 29.3,
//     mode: "Progrssion",
//     endurance: "1 - 175",
//     hunger: "0 - 15",
//     thirst: "0 - 20",
//     server: "Legacy",
//     health: "25 - 25",
//     buyTime: "07 декабря 2024, 11:52",
//     appearance: "Добавление джува в слот",
//     updated: "07 декабря 2024, 11:52",
//     genom: "1.0",
//     feature: "Обычный динозавр",
//     coordinates: "Доступен спавн",
//     id: 2171588,
//     slotId: 643314,
//   },
//   {
//     image: dinosaurImg2,
//     tier: 2,
//     name: "ХВОСТОЕБ",
//     type: dinosaurTypes.herbivorous,
//     teeth: 75,
//     oa: 300,
//     growth: dinosaurGrowths.Sub,
//     weight: 25000,
//   },
//   {
//     image: dinosaurImg3,
//     tier: 1,
//     name: "Авацератопс",
//     type: dinosaurTypes.herbivorous,
//     teeth: 75,
//     oa: 300,
//     growth: dinosaurGrowths.Adult,
//     weight: 1000,
//     damage: 25,
//     speed: 29.3,
//     mode: "Progrssion",
//     endurance: "1 - 130",
//     hunger: "0 - 120",
//     thirst: "0 - 50",
//     server: "Legacy",
//     health: "1000 - 1000",
//   },
//   {
//     image: dinosaurImg4,
//     tier: 4,
//     name: "ОРЕАЛКИН",
//     type: dinosaurTypes.herbivorous,
//     teeth: 75,
//     oa: 300,
//     growth: dinosaurGrowths.Adult,
//     weight: 2500,
//   },
//   {
//     image: dinosaurImg5,
//     tier: 1,
//     name: "СМОТРЯЩИЙ",
//     type: dinosaurTypes.predator,
//     teeth: 75,
//     oa: 300,
//     growth: dinosaurGrowths.Adult,
//     weight: 2000,
//   },
//   {
//     image: dinosaurImg6,
//     tier: 1,
//     name: "Ютараптор",
//     type: dinosaurTypes.predator,
//     teeth: 75,
//     oa: 300,
//     growth: dinosaurGrowths.Adult,
//     weight: 1000,
//     damage: 200,
//     speed: 43,
//     mode: "Progrssion",
//     endurance: "200 - 300",
//     hunger: "75 - 300",
//     thirst: "45 - 60",
//     server: "Legacy",
//     health: "300 - 1200",
//     growthTime: 60,
//     description: `Юта довольно социальное животное, предпочитающее жить и охотиться в больших стаях. Может одна особь и не доставит особых проблем своему оппоненту, но стоит им собраться группой и беды уже не миновать. Свое слабое здоровье и силу укуса они компенсируют скоростью и маневренностью. Кроме этого, благодаря хорошо развитым мышцам ног, Юта способна довольно высоко прыгать, что делает ее еще более проблемной мишенью. Так же считается самым "плодовитым" динозавром на острове. В еде Ютараптор не привередлив и питается абсолютно всеми видами останков.

// Плюсы

// +Прыжки

// +Высокая Скорость

// +Хорошая Маневренность

// +Большая Выносливость

// Минусы

// -Низкий Урон

// -Низкий урон Кровью

// -Малое количество Здоровья

// -Слабая регенерация Здоровья

// -Долгая регенерация Крови`,
//   },
//   {
//     image: dinosaurImg7,
//     tier: 3,
//     name: "ШМЕНАТОПС",
//     type: dinosaurTypes.predator,
//     teeth: 75,
//     oa: 300,
//     growth: dinosaurGrowths.Sub,
//     weight: 1000,
//   },
//   {
//     image: dinosaurImg8,
//     tier: 4,
//     name: "НОРМИС",
//     type: dinosaurTypes.predator,
//     teeth: 75,
//     oa: 300,
//     growth: dinosaurGrowths.Sub,
//     weight: 500,
//   },
// ];

// export const catalogData = [
//   ...casesData,
//   ...bustersData,
//   ...eggsData,
//   ...dinosaursData2,
// ];
// catalogData.forEach((item, i) => {
//   item.id = i + 1;
// });

// export const testDinosaurData = {
//   image: dinosaurImg1,
//   tier: 1,
//   name: "Пситтакозавр",
//   type: dinosaurTypes.herbivorous,
//   teeth: 75,
//   oa: 300,
//   growth: dinosaurGrowths.Sub,
//   weight: 50,
//   damage: 25,
//   speed: 29.3,
//   mode: "Progrssion",
//   endurance: "1 - 175",
//   hunger: "0 - 15",
//   thirst: "0 - 20",
//   server: "Legacy",
//   health: "25 - 25",
//   buyTime: "07 декабря 2024, 11:52",
//   appearance: "Добавление джува в слот",
//   updateTime: "07 декабря 2024, 11:52",
//   genom: "1.0",
//   feature: "Обычный динозавр",
//   coordinates: "Доступен спавн",
//   id: 2171588,
//   slotId: 643314,
// };

export const catalogCategoriesEng = {
  predator: "Плотоядные",
  herbivorous: "Травоядные",
  omnivorous: "Всеядные",
  items: "Предметы",
};

export const catalogCategories = {
  Плотоядные: "Плотоядные",
  Травоядные: "Травоядные",
  Всеядные: "Всеядные",
  Предметы: "Предметы",
};

export const catalogItemTypes = {
  dinosaurs: "Динозавры",
  items: "Предметы",
};

const dinosaursData = [
  {
    name_ru: "Дейнозух",
    name_eng: "Deinosuchus",
    cost: 300,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194544239925809204,
    emoji: 1222077808436711434,
    image_url:
      "https://cdn.discordapp.com/attachments/1301144411564150825/1301144411903623200/image.png?ex=675a1ee9&is=6758cd69&hm=0c0d9d18387393c22f3c92edb029de075f0f5872266681919f0dc1cf88a77d77&",
    is_visible: 1,
    id: 1,
    server: 1,
  },
  {
    name_ru: "Древняя эссенция",
    name_eng: "Heal",
    cost: 50,
    item_type: "Предметы",
    category: "Предметы",
    grow: 0,
    cooldown_sec: 0,
    description_channel_id: 1213921994488291390,
    emoji: 1253379484577693696,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1215379517338550282/icons8-mana-96.png?ex=65fc8961&is=65ea1461&hm=bd43c909e7840d857e84b10896d69f92387a2aa7684dfeccfd20826f62c68fcd&",
    is_visible: 1,
    id: 2,
    server: 1,
  },
  {
    name_ru: "Желудок",
    name_eng: "HungerFull",
    cost: 20,
    item_type: "Предметы",
    category: "Предметы",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1216084118748790908,
    emoji: 1253410297516134522,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1215379417250144346/icons8--96.png?ex=65fc8949&is=65ea1449&hm=813d8e7d15a8151c743b111daf4af1b93e125cdb6dfb582d748c14eac137e218&",
    is_visible: 1,
    id: 3,
    server: 1,
  },
  {
    name_ru: "Утилизация (убить)",
    name_eng: "Slay",
    cost: 10,
    item_type: "Предметы",
    category: "Предметы",
    grow: 0,
    cooldown_sec: 0,
    description_channel_id: 1213921752204185630,
    emoji: 1253379481717047346,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1215379313457631252/icons8-recycle-963.png?ex=65fc8930&is=65ea1430&hm=3eaf2a78662b6533c514a4aa24237bfa33d875dd48023f3d8fb5f6ccbffdf85e&",
    is_visible: 1,
    id: 4,
    server: 1,
  },
  {
    name_ru: "Карнотавр",
    name_eng: "Carnotaurus",
    cost: 220,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194549796548132894,
    emoji: 1222077793840664617,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1253405524871872553/027d5fa487d17de3.png?ex=6675bc5c&is=66746adc&hm=b11b370ae0229986170a21d40e90a4f5e66cf44eb538acbaf1ff0dac5cdf1f1a&",
    is_visible: 1,
    id: 5,
    server: 1,
  },
  {
    name_ru: "Цератозавр",
    name_eng: "Ceratosaurus",
    cost: 270,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194545610737586236,
    emoji: 1222077791743508540,
    image_url:
      "https://cdn.discordapp.com/attachments/1301143659126853662/1301143659269455872/2024-08-21_140710370.png?ex=675a1e36&is=6758ccb6&hm=a542a7f5a145998313a2ec404189a7b897db243a185293fbc9762cdc9a286f42&",
    is_visible: 1,
    id: 6,
    server: 1,
  },
  {
    name_ru: "Дилофозавр",
    name_eng: "Dilophosaurus",
    cost: 180,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194550258370363462,
    emoji: 1222077797485514782,
    image_url:
      "https://cdn.discordapp.com/attachments/1301147019414343711/1301147020291211395/2024-08-21_142209687.png?ex=675a2157&is=6758cfd7&hm=da73b41cff5af3972aae1d22c58654068407c1b2d8ba773b705116e391ddc3e5&",
    is_visible: 1,
    id: 7,
    server: 1,
  },
  {
    name_ru: "Омнираптор",
    name_eng: "Omniraptor",
    cost: 100,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194550463962546207,
    emoji: 1222077795883159624,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1253405524150714388/ecfd47fe4678c357.png?ex=6675bc5c&is=66746adc&hm=dbdaf30fc48373c5881d90210820ebb0277cc94a9a67eed108b0b1ca1bd87c73&",
    is_visible: 1,
    id: 8,
    server: 1,
  },
  {
    name_ru: "Герреразавр",
    name_eng: "Herrerasaurus",
    cost: 100,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194550091156049970,
    emoji: 1222077800559935558,
    image_url:
      "https://cdn.discordapp.com/attachments/1301146431482105858/1301146431716855809/2024-08-21_141929378.png?ex=675a20cb&is=6758cf4b&hm=a7175d34b496d6d8d6a1d0b214d61c4f00d9a9d5bd78208aaa344856cad13090&",
    is_visible: 1,
    id: 9,
    server: 1,
  },
  {
    name_ru: "Троодон",
    name_eng: "Troodon",
    cost: 50,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194550801482403890,
    emoji: 1222077810470817843,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1253405522615341117/45fb1bed9d8cb2d4.png?ex=6675bc5b&is=66746adb&hm=5495644dc139a247957441c9231da226ae1d9507172c1746b7d97f2b2a25f530&",
    is_visible: 1,
    id: 10,
    server: 1,
  },
  {
    name_ru: "Птеранодон",
    name_eng: "Pteranodon",
    cost: 30,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194550609270018139,
    emoji: 1222077812484341820,
    image_url:
      "https://cdn.discordapp.com/attachments/1301145830342004769/1301145830526287882/2024-08-21_141636187.png?ex=675a203c&is=6758cebc&hm=7ebf7e6dc2ddf467b82ced748242ee2d51c5526f16d1ca415223fcd849fa5dbb&",
    is_visible: 1,
    id: 11,
    server: 1,
  },
  {
    name_ru: "Стегозавр",
    name_eng: "Stegosaurus",
    cost: 300,
    item_type: "Динозавры",
    category: "Травоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194551686161772554,
    emoji: 1222077814090760292,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1253405492747964496/ebaaf985585853b6.png?ex=6675bc54&is=66746ad4&hm=dd0ce7ebea2d04d1d53f5cdcbf84ab2027bd1be4917b41bff9811dca401cdad8&",
    is_visible: 1,
    id: 12,
    server: 1,
  },
  {
    name_ru: "Тенонтозавр",
    name_eng: "Tenontosaurus",
    cost: 200,
    item_type: "Динозавры",
    category: "Травоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194551492330393681,
    emoji: 1222078567513460767,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1253405491506188419/35487c1e01fe0128.png?ex=6675bc54&is=66746ad4&hm=7c048cdae8b0a87420919289c5cac78662e006c8d80b85089417d891c5761704&",
    is_visible: 1,
    id: 13,
    server: 1,
  },
  {
    name_ru: "Пахицефалозавр",
    name_eng: "Pachycephalosaurus",
    cost: 150,
    item_type: "Динозавры",
    category: "Травоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1195394488928055397,
    emoji: 1222078569354625054,
    image_url:
      "https://cdn.discordapp.com/attachments/1301151154394108025/1301151154675388488/image.png?ex=675a2531&is=6758d3b1&hm=e908499d3e0056fec8163e08b793a36cd3122dd44f0baf526e20e454af73084d&",
    is_visible: 1,
    id: 14,
    server: 1,
  },
  {
    name_ru: "Дриозавр",
    name_eng: "Dryosaurus",
    cost: 30,
    item_type: "Динозавры",
    category: "Травоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194551289464496128,
    emoji: 1222077816082923603,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1253405493288763513/de5dc034d0bc99c1.png?ex=6675bc54&is=66746ad4&hm=f0a71f1c9f31fb948e95ff19b1c040844885e87081e3f9728de90850216cff09&",
    is_visible: 1,
    id: 15,
    server: 1,
  },
  {
    name_ru: "Галлимимус",
    name_eng: "Gallimimus",
    cost: 100,
    item_type: "Динозавры",
    category: "Всеядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1195044410799423588,
    emoji: 1222077819870380063,
    image_url:
      "https://cdn.discordapp.com/attachments/1301148353354137620/1301148353517846579/2024-08-21_142716771.png?ex=675a2295&is=6758d115&hm=b771715dbd7dfdbb2e0ef1d5a1d46e8f63d0e08ba935f1d8c5a3f2399b89d0b2&",
    is_visible: 1,
    id: 16,
    server: 1,
  },
  {
    name_ru: "Бэйпяозавр",
    name_eng: "Beipiaosaurus",
    cost: 30,
    item_type: "Динозавры",
    category: "Всеядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1195050720819421315,
    emoji: 1222077817920163861,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1253405540839592047/49ff702849dd4462.png?ex=6675bc60&is=66746ae0&hm=fa65eaf7a17492483eb7829c1f43e2eeab76f75cb9f516ffeace9ad2497cf99f&",
    is_visible: 1,
    id: 17,
    server: 1,
  },
  {
    name_ru: "Дейнозух",
    name_eng: "Deinosuchus",
    cost: 300,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194544239925809204,
    emoji: 1222077808436711434,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1253405526541471834/10932dd94467c501.png?ex=6675bc5c&is=66746adc&hm=5d246b7280a67d763dbf5a11b2f9d2a934c6a1dc21dbdd9095613e91067f3972&",
    is_visible: 1,
    id: 18,
    server: 2,
  },
  {
    name_ru: "Древняя эссенция",
    name_eng: "Heal",
    cost: 50,
    item_type: "Предметы",
    category: "Предметы",
    grow: 0,
    cooldown_sec: 0,
    description_channel_id: 1213921994488291390,
    emoji: 1253379484577693696,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1215379517338550282/icons8-mana-96.png?ex=65fc8961&is=65ea1461&hm=bd43c909e7840d857e84b10896d69f92387a2aa7684dfeccfd20826f62c68fcd&",
    is_visible: 1,
    id: 19,
    server: 2,
  },
  {
    name_ru: "Желудок",
    name_eng: "HungerFull",
    cost: 20,
    item_type: "Предметы",
    category: "Предметы",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1216084118748790908,
    emoji: 1253410297516134522,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1215379417250144346/icons8--96.png?ex=65fc8949&is=65ea1449&hm=813d8e7d15a8151c743b111daf4af1b93e125cdb6dfb582d748c14eac137e218&",
    is_visible: 1,
    id: 20,
    server: 2,
  },
  {
    name_ru: "Утилизация (убить)",
    name_eng: "Slay",
    cost: 10,
    item_type: "Предметы",
    category: "Предметы",
    grow: 0,
    cooldown_sec: 0,
    description_channel_id: 1213921752204185630,
    emoji: 1253379481717047346,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1215379313457631252/icons8-recycle-963.png?ex=65fc8930&is=65ea1430&hm=3eaf2a78662b6533c514a4aa24237bfa33d875dd48023f3d8fb5f6ccbffdf85e&",
    is_visible: 1,
    id: 21,
    server: 2,
  },
  {
    name_ru: "Карнотавр",
    name_eng: "Carnotaurus",
    cost: 220,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194549796548132894,
    emoji: 1222077793840664617,
    image_url:
      "https://cdn.discordapp.com/attachments/1301142147218800660/1301142147394965564/2024-08-21_140337735.png?ex=675a1ccd&is=6758cb4d&hm=58b48d10b132b0786d5ab703085974393ac2982bf8144671b14a4f95fbf852a6&",
    is_visible: 1,
    id: 22,
    server: 2,
  },
  {
    name_ru: "Цератозавр",
    name_eng: "Ceratosaurus",
    cost: 270,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194545610737586236,
    emoji: 1222077791743508540,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1253405526088482918/361bb8c1074949c2.png?ex=6675bc5c&is=66746adc&hm=45a94c74037684b624a2f298e6bb0a7249e9694593aff38cbc9da2dcd2e55a6f&",
    is_visible: 1,
    id: 23,
    server: 2,
  },
  {
    name_ru: "Дилофозавр",
    name_eng: "Dilophosaurus",
    cost: 180,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194550258370363462,
    emoji: 1222077797485514782,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1253405523433492592/7447c7cd0c089743.png?ex=6675bc5b&is=66746adb&hm=91032023bf49b57f0c2f071d314e8381b8a46ab6006b301326f3c663f2135f87&",
    is_visible: 1,
    id: 24,
    server: 2,
  },
  {
    name_ru: "Омнираптор",
    name_eng: "Omniraptor",
    cost: 100,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194550463962546207,
    emoji: 1222077795883159624,
    image_url:
      "https://cdn.discordapp.com/attachments/1301147692981817384/1301147693472813096/2024-08-21_142453090.png?ex=675a21f8&is=6758d078&hm=e1469400dcb482d896990b7b558c626eb3b35e139263de6b05933347584010b5&",
    is_visible: 1,
    id: 25,
    server: 2,
  },
  {
    name_ru: "Герреразавр",
    name_eng: "Herrerasaurus",
    cost: 100,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194550091156049970,
    emoji: 1222077800559935558,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1253405525555810467/eb7e2362627370c0.png?ex=6675bc5c&is=66746adc&hm=200c14772682589d19a967958b2d995ee71af94c00a8088b062fe86c3541e8f5&",
    is_visible: 1,
    id: 26,
    server: 2,
  },
  {
    name_ru: "Троодон",
    name_eng: "Troodon",
    cost: 50,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194550801482403890,
    emoji: 1222077810470817843,
    image_url:
      "https://cdn.discordapp.com/attachments/1301145012179828737/1301145012976877648/2024-08-21_141332720.png?ex=675a1f79&is=6758cdf9&hm=ed8b52c53bd9aca69379fba14f26209a109bca58a1c3cb5934e15e9452154073&",
    is_visible: 1,
    id: 27,
    server: 2,
  },
  {
    name_ru: "Птеранодон",
    name_eng: "Pteranodon",
    cost: 30,
    item_type: "Динозавры",
    category: "Плотоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194550609270018139,
    emoji: 1222077812484341820,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1253405527250305136/d381da63f10905b5.png?ex=6675bc5c&is=66746adc&hm=f70dc47bc53f6244f378c63730efe6a56e94edf68f429d1484f2b7be943159cd&",
    is_visible: 1,
    id: 28,
    server: 2,
  },
  {
    name_ru: "Стегозавр",
    name_eng: "Stegosaurus",
    cost: 300,
    item_type: "Динозавры",
    category: "Травоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194551686161772554,
    emoji: 1222077814090760292,
    image_url:
      "https://cdn.discordapp.com/attachments/1301150586317570089/1301150586548260945/2024-08-21_143851991.png?ex=675a24aa&is=6758d32a&hm=c105ffd7ac687b4298fe76d18ac2cef62bd2008a0dd15e71054adebeb1cb1f48&",
    is_visible: 1,
    id: 29,
    server: 2,
  },
  {
    name_ru: "Тенонтозавр",
    name_eng: "Tenontosaurus",
    cost: 200,
    item_type: "Динозавры",
    category: "Травоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194551492330393681,
    emoji: 1222078567513460767,
    image_url:
      "https://cdn.discordapp.com/attachments/1301150034557014026/1301150034812735559/2024-08-21_143525582.png?ex=675a2426&is=6758d2a6&hm=b4f65a3c2fd8944a7bd8d76d8b24c8d31b0ff270dbb9b24353888b334ca46522&",
    is_visible: 1,
    id: 30,
    server: 2,
  },
  {
    name_ru: "Пахицефалозавр",
    name_eng: "Pachycephalosaurus",
    cost: 150,
    item_type: "Динозавры",
    category: "Травоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1195394488928055397,
    emoji: 1222078569354625054,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1253405492290519171/ec5b13b64cf154b0.png?ex=6675bc54&is=66746ad4&hm=0a8c83c8a146e9ff7f81f6696bf686912c2e17a78993be7386d0b485b1dc3b52&",
    is_visible: 1,
    id: 31,
    server: 2,
  },
  {
    name_ru: "Дриозавр",
    name_eng: "Dryosaurus",
    cost: 30,
    item_type: "Динозавры",
    category: "Травоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1194551289464496128,
    emoji: 1222077816082923603,
    image_url:
      "https://cdn.discordapp.com/attachments/1301149511611322379/1301149511875301386/2024-08-21_143317459.png?ex=675a23a9&is=6758d229&hm=8c50a4c0571b3d9a6c2431efdcedfede2dc49f241d23b603ded6d48491bf1bbe&",
    is_visible: 1,
    id: 32,
    server: 2,
  },
  {
    name_ru: "Галлимимус",
    name_eng: "Gallimimus",
    cost: 100,
    item_type: "Динозавры",
    category: "Всеядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1195044410799423588,
    emoji: 1222077819870380063,
    image_url:
      "https://cdn.discordapp.com/attachments/1197223722868805785/1253405541246566410/182aa1fe180df0e5.png?ex=6675bc60&is=66746ae0&hm=ccb69170ff497b9f8c295cc9e258273ddc3dcdda68b4af9c1bf443532733e886&",
    is_visible: 1,
    id: 33,
    server: 2,
  },
  {
    name_ru: "Бэйпяозавр",
    name_eng: "Beipiaosaurus",
    cost: 30,
    item_type: "Динозавры",
    category: "Всеядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1195050720819421315,
    emoji: 1222077817920163861,
    image_url:
      "https://cdn.discordapp.com/attachments/1301148932214100011/1301148932461821982/2024-08-21_143042098.png?ex=675a231f&is=6758d19f&hm=8a62a421db8fbc1329ae4179616803ffed049818810961419d3c064fe0bbfe01&",
    is_visible: 1,
    id: 34,
    server: 2,
  },
  {
    name_ru: "Диаблоцератопс",
    name_eng: "Diabloceratops",
    cost: 300,
    item_type: "Динозавры",
    category: "Травоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1270384957658890311,
    emoji: 1270387270083543101,
    image_url:
      "https://cdn.discordapp.com/attachments/1301153483029741599/1301153483218751549/image.png?ex=675a275c&is=6758d5dc&hm=ed1e708acb21f2ca2ebd0b17865eeaedd17f1556731db36ca154374b10f67559&",
    is_visible: 1,
    id: 41,
    server: 1,
  },
  {
    name_ru: "Диаблоцератопс",
    name_eng: "Diabloceratops",
    cost: 300,
    item_type: "Динозавры",
    category: "Травоядные",
    grow: 100.0,
    cooldown_sec: 0,
    description_channel_id: 1270384957658890311,
    emoji: 1270387270083543101,
    image_url:
      "https://cdn.discordapp.com/attachments/1270384957658890311/1270384958518595716/image.png?ex=66b381b0&is=66b23030&hm=15f31614cfb44087213d93ef8cfa2479f2bce62379416d09cd4f09c0d465355a&",
    is_visible: 1,
    id: 42,
    server: 2,
  },
];

export const catalogData = [...dinosaursData];
