import React from "react";
import { Link, useLocation } from "react-router-dom";
import userImg from "../../assets/img/avatar/Avatar_100.png";
import { ReactComponent as LogoutSvg } from "../../assets/svg/log_out.svg";
import { ReactComponent as SteamSvg } from "../../assets/svg/steam.svg";
import SmallComponentHeading from "../Headings/SmallComponentHeading";
import A from "../A";
import { steamProfile } from "../../utils/consts";
import { ReactComponent as ChangeSvg } from "../../assets/svg/refresh_02.svg";
import { ReactComponent as PlusSquareSvg } from "../../assets/svg/plus_square.svg";
import Button from "../Button";
import { ReactComponent as WalletSvg } from "../../assets/svg/wallet.svg";
import { ReactComponent as OaSvg } from "../../assets/svg/oa.svg";
import { ReactComponent as TeethSvg } from "../../assets/svg/teeth.svg";
import "../../css/user-menu.css";
import MiniHeading from "../Headings/MiniHeading";
import { useContext } from "react";
import { Context } from "../..";
import { paths } from "../../paths";
import MyLink from "../MyLink";
import userStore from "../../store/userStore";
import { observer } from "mobx-react-lite";

const MegaMenu = () => {
  const location = useLocation();
  const page = location.pathname;

  return (
    <>
      <div className="user-balance">
        <SmallComponentHeading className="user-balance-heading">
          <div className="actions">
            <MyLink
              to={paths.MegaBank + "?balance-input=1"}
              className="change small-text gradient-text extra"
            >
              <ChangeSvg />
              обменять
            </MyLink>
            <MyLink
              to={paths.MegaBank + "?balance-input=1"}
              className="replenish small-text gradient-text"
            >
              <PlusSquareSvg />
              пополнить
            </MyLink>
          </div>
        </SmallComponentHeading>
        <div className="user-wallet">
          <span className="teeth">
            <TeethSvg /> {userStore.userValutes.teeth || 0} зуб.
          </span>
        </div>
      </div>

      <div className="menu">
        <div className="buttons">
          <MyLink
            to={paths.MegaProfile}
            className={
              page === userStore.choosedServerUrl + paths.MegaProfile
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">Мой мегапрофиль</Button>
          </MyLink>
          {/* <MyLink
          
            to={paths.MyDinosaurs}
            className={
              page === userStore.choosedServerUrl + paths.MyDinosaurs
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">Мои динозавры</Button>
          </MyLink>
          <MyLink
            to={paths.MyStorage}
            className={
              page === userStore.choosedServerUrl + paths.MyStorage
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">ХРАНИЛИЩЕ</Button>
          </MyLink> */}
          <MyLink
            to={paths.MegaBank}
            className={
              page === userStore.choosedServerUrl + paths.MegaBank
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">МЕГАБАНК</Button>
          </MyLink>
          {/* <MyLink
            to={paths.MyStatistics}
            className={
              page === userStore.choosedServerUrl + paths.MyStatistics
                ? "active"
                : ""
            }
          >
            <Button className="black no-border spiked">Моя активность</Button>
          </MyLink> */}
        </div>
      </div>
    </>
  );
};

export default observer(MegaMenu);
