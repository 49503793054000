import React, { useEffect, useState } from "react";
import GlowingButton from "../GlowingButton";
import { ReactComponent as SteamSvg } from "../../../assets/svg/steam.svg";
import {
  cookieNames,
  notificationTimeouts,
  notificationTypes,
} from "../../../utils/consts";
import userStore from "../../../store/userStore";
import { showStandartNotification } from "../../../utils/helpers";
import {
  generateSteamAuthUrl,
  steamAuth,
} from "../../../api/auth/steam/steamAuth";
import { useLocation } from "react-router-dom";
import A from "../../A";
import { paths } from "../../../paths";
import { observer } from "mobx-react-lite";

const SteamAuthButton = (props) => {
  const [authUrl, setAuthUrl] = useState(null);

  // Получаем ссылку для авторизации при загрузке компонента
  useEffect(() => {
    (async () => {
      if (!userStore.isAuth) {
        try {
          console.log(
            "process.env.REACT_APP_SERVER_URL: ",
            process.env.REACT_APP_SERVER_URL
          );
          const { auth_url } = await generateSteamAuthUrl();
          setAuthUrl(auth_url);
          console.log(auth_url);
        } catch (err) {
          console.error(err);
        }
      }
    })();
  }, []);

  async function handleSteamAuth() {
    if (userStore.isAuth) {
      return showStandartNotification({
        text: "Вы уже вошли в профиль, для начала выйдите из него",
        timeout: notificationTimeouts.short,
        type: notificationTypes.reject,
      });
    }

    try {
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <GlowingButton
      onClick={handleSteamAuth}
      className="neon no-border extra spiked scale-h-1-05"
    >
      <a href={authUrl} className="text-text d-flex align-items-center gap-0-5">
        <SteamSvg className="svg-text" /> {props.text || "ВОЙТИ В ПРОФИЛЬ"}
      </a>
    </GlowingButton>
  );
};

export default observer(SteamAuthButton);
