import React, { useState } from "react";
import "./snow.min.css";
import { Icon } from "@iconify/react";
import "../../css/snowflakes.css";
import Dropdown from "../Dropdown";

const SnowFlakes = () => {
  const [isSnowing, setIsSnowing] = useState(false);
  let snowInstance = null;

  const handleButtonClick = () => {
    if (isSnowing) {
      // Остановка снежинок
      if (snowInstance && typeof snowInstance.destroy === "function") {
        snowInstance.destroy(); // Удаление, если в библиотеке есть метод destroy
        snowInstance = null;
      } else {
        // Альтернативный метод, если destroy отсутствует
        const snowElements = document.querySelectorAll(".snowflakes-box"); // Класс снежинок
        snowElements.forEach((el) => el.remove());
        const snowElements2 = document.querySelectorAll(".snowball-box");
        snowElements2.forEach((el) => el.remove());
      }
    } else {
      // Запуск снежинок
      if (window.Snow) {
        snowInstance = new window.Snow();
      } else {
        console.error("Snow.js is not loaded");
      }
    }
    setIsSnowing(!isSnowing);
  };

  return (
    <div className="snowflake-button">
      <button onClick={handleButtonClick}>
        <Dropdown
          noArrow={true}
          value={
            <Icon icon="ion:snow" className={`${isSnowing ? "snowing" : ""}`} />
          }
        >
          <span>Добавить настроения?</span>
        </Dropdown>
      </button>
    </div>
  );
};

export default SnowFlakes;
